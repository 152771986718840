import { useMutation } from '@tanstack/react-query';
import { ArrowLeftCircle, Download } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'sam-ui-kit';

import { downloadFile } from 'utils/fileUtils';

import { exportSituations } from 'modules/Situations/services';

import styles from './SituationDetailsPageHeader.module.scss';

export const SituationDetailsPageHeader = ({ data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/');
    };

    const mutation = useMutation({
        mutationFn: () => {
            return exportSituations(data.id);
        },
        onSuccess: (response) => {
            downloadFile(response, `${t('situation')} №${data.id}`);
        }
    });

    return (
        <div className={styles.header}>
            <h1 className={styles.pageTitle}>
                <ArrowLeftCircle
                    color="#888888"
                    size={26}
                    onClick={handleBack}
                    cursor="pointer"
                />
                {t('train')} №{data.nomPoezd || ''}
            </h1>
            <div className={styles.actions}>
                <Button
                    label={t('actions.download')}
                    onClick={mutation.mutate}
                    variant="primary"
                    loading={mutation.isPending}
                    icon={<Download size={16} />}
                />
            </div>
        </div>
    );
};
