import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'sam-ui-kit';

import { Form } from 'components/Form/Form';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormSegmentControlField } from 'components/Form/FormSegmentControlField';
import { FormTextareaField } from 'components/Form/FormTextareaField';
import { RequiredField } from 'components/Form/rules';

import { getSituationWagonData } from 'modules/Applications/utils';
import { DEFAULT_WAGON_DEFECT_FILTER } from 'modules/Situations/consts';
import {
    getSituationWagons,
    getWagonDefectByCode,
    saveSituationWagons
} from 'modules/Situations/services';

import styles from './SituationsCarriageViewForm.module.scss';

export const SituationsCarriageViewForm = ({
    wagonId,
    wagonCode,
    situationId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSelect = useCallback(
        (response) => getSituationWagonData(response, wagonId),
        [wagonId]
    );

    const { data: situationsWagonsData, isLoading } = useQuery({
        queryFn: async () => {
            const defaultWagonDefect = await getWagonDefectByCode(
                DEFAULT_WAGON_DEFECT_FILTER
            );
            const situationsData = await getSituationWagons(situationId);
            return {
                situationsData: situationsData,
                defaultWagonDefect: defaultWagonDefect
            };
        },
        queryKey: ['SituationsCarriageViewForm', situationId],
        initialData: {
            situationsData: {
                id: situationId,
                wagons: []
            }
        },
        select: handleSelect
    });

    const methods = useForm({
        defaultValues: {
            wagonAcceptanceInSituation: '',
            wagonAcceptanceInSituationDefect: '',
            wagonAcceptanceInSituationText: ''
        }
    });

    useEffect(() => {
        if (situationsWagonsData.wagons.length) {
            methods.reset({
                ...situationsWagonsData.wagons[0]
            });
        }
    }, [situationsWagonsData, methods]);

    const saveMutation = useMutation({
        mutationFn: (data) => {
            return saveSituationWagons({
                ...situationsWagonsData,
                wagons: [data]
            });
        },
        onSuccess: () => {
            navigate(`/situations/${situationId}#${wagonCode}`);
        }
    });

    const acceptable = methods.getValues('acceptable');

    if (!acceptable) return null;

    return (
        <div className={styles.fields}>
            <Form
                methods={methods}
                onSubmit={saveMutation.mutate}
                disabled={saveMutation.isPending || isLoading}
            >
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <FormSegmentControlField
                            name={'wagonAcceptanceInSituation'}
                            segmentsConfig={[
                                {
                                    label: t('actions.accepting'),
                                    value: 'true'
                                },
                                {
                                    label: t('actions.not_accepting'),
                                    value: 'false'
                                }
                            ]}
                            variant="button"
                            disableHint
                            noHintSpace
                            rules={RequiredField}
                        />
                    </Col>
                    <Col span={12}>
                        <FormAutocomplete
                            clearable
                            name={'wagonAcceptanceInSituationDefect'}
                            catalogName="WAGON_DEFECTS"
                            useFullModel
                            disableHint
                            noHintSpace
                            rules={RequiredField}
                        />
                    </Col>
                    <Col span={12}>
                        <FormTextareaField
                            name={'wagonAcceptanceInSituationText'}
                            rows={2}
                            placeholder={t('situations:enter_note_if_exists')}
                            noHintSpace={true}
                        />
                    </Col>
                    <Col span={12}>
                        <Button
                            label={t('actions.save')}
                            block={true}
                            disabled={saveMutation.isPending || isLoading}
                            variant="primary"
                            size="lg"
                            type="submit"
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
