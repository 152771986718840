export const downloadFile = (response, fileName = '', isCsv = false) => {
    const link = document.createElement('a');
    if (isCsv) {
        const universalBOM = '\uFEFF';
        const csv = universalBOM + response.data;
        const csvA = new Uint16Array(
            csv.split('').map(function (k, v) {
                return k.charCodeAt(0);
            })
        );

        link.setAttribute(
            'href',
            window.URL.createObjectURL(
                new Blob([csvA], { type: 'text/csv;charset=UTF-16LE;' })
            )
        );
    } else {
        link.setAttribute(
            'href',
            window.URL.createObjectURL(new Blob([response.data]))
        );
    }

    const extension = getFileExtension(response.headers['content-disposition']);

    link.setAttribute(
        'download',
        fileName.length
            ? `${fileName}.${extension}`
            : response.headers['content-disposition']
                  .split(';')[1]
                  .split('=')[1]
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const getFileExtension = (fileName) => {
    if (!fileName) return '';
    const arr = fileName.split('.');
    return arr[arr.length - 1];
};
