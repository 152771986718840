import { notifications } from 'sam-ui-kit';

import { getErrors } from 'utils/serverErrors';

import { wagonRequestToFormData } from 'modules/Situations/utils';

import {
    ACCEPTED,
    ACCEPTED_WITHOUT_DEFECT,
    ACCEPTED_WITH_DEFECT,
    ACCEPTED_WITH_GFA,
    AWAITING_ACCEPTANCE,
    DETACHED,
    REJECTED
} from './consts';

export const getStatusOptions = (t) => {
    return [
        {
            label: t('applications:status_cargo.ACCEPTED_WITHOUT_DEFECT'),
            value: ACCEPTED_WITHOUT_DEFECT
        },
        {
            label: t('applications:status_cargo.ACCEPTED_WITH_GFA'),
            value: ACCEPTED_WITH_GFA
        },
        {
            label: t('applications:status_cargo.ACCEPTED_WITH_DEFECT'),
            value: ACCEPTED_WITH_DEFECT
        },
        {
            label: t('applications:status_cargo.REJECTED'),
            value: REJECTED
        }
    ];
};

export const getApplicationStatusOptions = (t) => {
    return [
        {
            label: t('applications:status_cargo.AWAITING_ACCEPTANCE'),
            value: AWAITING_ACCEPTANCE
        },
        {
            label: t('applications:status_cargo.ACCEPTED'),
            value: ACCEPTED
        },
        {
            label: t('applications:status_cargo.DETACHED'),
            value: DETACHED
        }
    ];
};

export const notificateApplicationsCarriageResponse = (response, t) => {
    const errors = getErrors(response);
    errors.forEach(({ message, fields }) => {
        let description;
        if (
            message ===
            'backendValidation.backend.validation.qorgau.application.wagonNotAvailable'
        ) {
            description =
                t(`backendValidation:${message}`) + ': ' + fields.join(', ');
        } else {
            description = t([
                `backendValidation:${message}`,
                'backendValidation:fallbackValidationError'
            ]);
        }
        notifications.push({
            type: 'error',
            title: t('error'),
            description
        });
    });
};

export const getSituationWagonData = (response, wagonId) => {
    const data = {
        id: response.situationsData.id,
        wagons: [
            wagonRequestToFormData(
                response.situationsData.wagons?.find(
                    (item) => item.appWagonId === wagonId
                ) || []
            )
        ]
    };
    if (response.defaultWagonDefect) {
        data?.wagons.forEach((wagon) => {
            if (!wagon.wagonAcceptanceInSituationDefect) {
                wagon.wagonAcceptanceInSituationDefect =
                response.defaultWagonDefect[0];
            }
        });
    }
    return data;
};
